html,
body,
.ant-list {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  background: #000;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  overflow-x: hidden; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 700; }

h1 {
  font-size: 20px; }

h2 {
  font-size: 18px; }

h3,
h5,
h6 {
  font-size: 16px; }

p {
  font-size: 14px; }

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

* {
  box-sizing: border-box; }

a {
  color: #3fcaff; }

input[type="text"]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #313c52;
  opacity: 1;
  /* Firefox */ }

input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #313c52; }

input[type="text"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #313c52; }

.primary-button {
  background-color: transparent !important;
  border-radius: 4px !important;
  border: solid 1px #ff54ae !important;
  color: #ff54ae !important; }
  .primary-button.inverse {
    background-color: #ff54ae !important;
    border: solid 1px #ff54ae !important;
    color: #fff !important; }

.col-justify-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.col-justify-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.row-align-between {
  display: flex;
  align-items: space-between; }

.row-align-start {
  display: flex;
  align-items: flex-start; }

.row-align-center {
  display: flex;
  align-items: center; }

.row-align-end {
  display: flex;
  align-items: flex-end; }

.row-space-between {
  display: flex;
  justify-content: space-between; }

.row-space-around {
  display: flex;
  justify-content: space-around; }

.col {
  display: flex;
  flex-direction: column; }

.text {
  color: #9f9faf; }

.text-black {
  color: #0a0a0a; }

.text-grey {
  color: #a6a7a8; }

.text-light-grey {
  color: #e5e5e5; }

.text-off-white {
  color: #f4f4f4; }

.text-white {
  color: #fff; }

.text-blue {
  color: #3fcaff; }

.text-green {
  color: #0ab06a; }

.text-yellow {
  color: #f7e07e; }

.text-warning {
  color: #ff597a; }

.primary {
  color: #ff54ae; }

.secondary {
  color: #07dee7; }

.small {
  font-size: 0.875rem; }

.big {
  font-size: 1.125rem; }

.uppercase {
  text-transform: uppercase; }

.hover-link:hover {
  cursor: pointer;
  color: #ff54ae;
  transition: color 250ms; }

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.circular-loading {
  position: absolute;
  z-index: 99999;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #242424;
  color: #fff; }

.fade-in-out {
  opacity: 0;
  transition: opacity 250ms ease-in-out; }
  .fade-in-out.hover {
    opacity: 1; }

.mobile-portrait-visible {
  display: none; }
  @media only screen and (max-width: 500px) {
    .mobile-portrait-visible {
      display: flex; } }

@media only screen and (max-width: 500px) {
  .mobile-portrait-hidden {
    display: none; } }

.mobile-landscape-visible {
  display: none; }
  @media only screen and (max-height: 600px) {
    .mobile-landscape-visible {
      display: flex; } }

@media only screen and (max-height: 600px) {
  .mobile-landscape-hidden {
    display: none !important; } }

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ant-popover-inner,
.ant-notification-notice {
  background: #000 !important;
  border: 1px solid #07dee7; }
  .ant-popover-inner svg,
  .ant-notification-notice svg {
    fill: #07dee7; }
  .ant-popover-inner svg[data-icon="close"],
  .ant-notification-notice svg[data-icon="close"] {
    fill: transparent; }

.ant-popover-message,
.ant-notification-notice-message {
  color: #fff !important; }

@font-face {
  font-family: "hunt-icon";
  src: url("styles/fonts/hunt-icon.eot");
  src: url("styles/fonts/hunt-icon.eot?#iefix") format("embedded-opentype"), url("styles/fonts/hunt-icon.woff") format("woff"), url("styles/fonts/hunt-icon.ttf") format("truetype"), url("styles/fonts/hunt-icon.svg#hunt-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "hunt-icon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "hunt-icon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-facebook:before {
  content: "\61"; }

.icon-twitter:before {
  content: "\62"; }

.icon-pinterest-p:before {
  content: "\63"; }

.icon-link:before {
  content: "\64"; }

.header {
  display: flex;
  height: 90px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 30px; }
  @media only screen and (max-width: 500px) {
    .header {
      padding: 20px 10px;
      height: 50px; } }
  .header .header-logo {
    width: 91px;
    height: 68px;
    object-fit: contain; }
    @media only screen and (max-width: 500px) {
      .header .header-logo {
        width: auto;
        height: 25px; } }

.mobile-youtube-header {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  width: 100vw;
  height: 50px;
  z-index: 5;
  color: #07dee7;
  background-color: #000;
  font-size: 1.5rem;
  padding: 0 10px; }
  @media only screen and (max-width: 500px) {
    .mobile-youtube-header {
      display: flex; } }
  .mobile-youtube-header .flag-button {
    width: 20px;
    height: 20px; }
  .mobile-youtube-header .youtube-button,
  .mobile-youtube-header .share-button {
    width: 24px;
    height: 24px; }
  .mobile-youtube-header .flag-button,
  .mobile-youtube-header .youtube-button {
    margin-right: 16px; }

.youtube-container {
  position: absolute;
  height: 100%;
  background-color: #000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden; }
  @media only screen and (max-width: 500px) {
    .youtube-container {
      z-index: 1000;
      transition: transform 250ms ease-in-out;
      transform: translateX(100vw); } }
  .youtube-container .video-container {
    display: flex;
    position: relative;
    width: calc(100vw - 360px);
    height: calc(100% - 50px - 90px); }
    @media only screen and (max-width: 500px) {
      .youtube-container .video-container {
        height: calc(100% - 100px); } }
    @media only screen and (max-height: 600px) {
      .youtube-container .video-container {
        height: calc(100% - 50px); } }
    .youtube-container .video-container iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute; }
  .youtube-container.fullscreen {
    transform: translateX(0); }
    .youtube-container.fullscreen .video-container {
      width: 100vw; }
      @media only screen and (max-width: 500px) {
        .youtube-container.fullscreen .video-container {
          width: 100vw; } }
  .youtube-container .theatre-img,
  .youtube-container .fullscreen-img {
    width: 18px;
    height: 18px; }
  .youtube-container .fullscreen-img {
    margin-left: 16px; }
  .youtube-container .play-button,
  .youtube-container .forward-button {
    font-size: 1.625rem; }
  .youtube-container .play-button {
    margin-right: 4px; }
  .youtube-container .forward-button {
    margin-right: 8px; }
  .youtube-container .sound-button {
    width: 1.625rem;
    height: 1.625rem; }
  .youtube-container .sound-control {
    display: flex;
    align-items: center;
    padding-right: 16px; }
    .youtube-container .sound-control .volume-slider {
      top: 3px;
      width: 0px;
      transition: width 0.1s ease-in-out; }
    .youtube-container .sound-control .ant-slider:hover .ant-slider-track,
    .youtube-container .sound-control .ant-slider-track {
      background-color: #fff !important; }
    .youtube-container .sound-control:hover .ant-slider-handle {
      opacity: 1 !important; }
    .youtube-container .sound-control:hover .volume-slider {
      width: 100px;
      margin-left: 14px; }
  .youtube-container .controls {
    position: relative;
    z-index: 2;
    height: 50px;
    padding: 0 20px; }
    @media only screen and (max-width: 500px) {
      .youtube-container .controls {
        width: 100vw;
        padding: 0px 10px 0px; } }
    @media only screen and (max-height: 600px) {
      .youtube-container .controls {
        padding: 0px 10px 0px; } }
    .youtube-container .controls .ant-slider-handle {
      opacity: 0; }
    .youtube-container .controls .ant-slider {
      margin: 0;
      padding: 0; }
    .youtube-container .controls .ant-slider:hover .ant-slider-rail,
    .youtube-container .controls .ant-slider-rail {
      background-color: rgba(255, 255, 255, 0.2); }
    .youtube-container .controls .ant-slider:hover .ant-slider-track,
    .youtube-container .controls .ant-slider-track {
      background-color: #07dee7; }
  .youtube-container .slider-container {
    display: flex;
    margin: auto;
    color: #fff;
    align-items: center;
    justify-content: space-between; }
  .youtube-container .slider {
    display: flex;
    flex: 1; }
  .youtube-container .hover-controls {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 90px;
    padding-bottom: 90px;
    left: 0;
    background-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.95), black, black);
    opacity: 0;
    transition: opacity 250ms ease-in-out; }
    @media only screen and (max-width: 500px) {
      .youtube-container .hover-controls {
        width: 100vw;
        top: 40px; } }
    @media only screen and (max-height: 600px) {
      .youtube-container .hover-controls {
        top: 0px; } }
    .youtube-container .hover-controls .middle-container {
      position: relative;
      z-index: 3; }
      @media only screen and (max-height: 600px) {
        .youtube-container .hover-controls .middle-container {
          margin-top: 50px; } }
    .youtube-container .hover-controls.paused {
      opacity: 1;
      pointer-events: auto; }
    .youtube-container .hover-controls .upvote-button {
      margin-top: 1.75rem;
      border-radius: 7px;
      border: solid 1px #ff54ae;
      background-color: rgba(17, 23, 36, 0.65);
      color: #ff54ae;
      padding: 14px 32px;
      cursor: pointer; }
      .youtube-container .hover-controls .upvote-button.voted {
        background-color: #ff54ae;
        color: #fff; }
    .youtube-container .hover-controls .play-icon {
      margin: 0 90px; }
      .youtube-container .hover-controls .play-icon.mobile {
        margin: 0 40px; }
    .youtube-container .hover-controls i {
      font-size: 42px;
      color: #fff;
      cursor: pointer; }
  .youtube-container .tap-to-unmute {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 70px;
    left: 20px;
    background-color: #fff;
    border-radius: 6px;
    padding: 8px 15px;
    font-weight: bold;
    color: #000;
    z-index: 5; }
    @media only screen and (max-width: 500px) {
      .youtube-container .tap-to-unmute {
        left: 0.625rem; } }
    @media only screen and (max-height: 600px) {
      .youtube-container .tap-to-unmute {
        bottom: 3.75rem;
        left: 0.625rem; } }
    .youtube-container .tap-to-unmute .unmute-img {
      width: 20px;
      height: 20px;
      margin-right: 8px; }
  .youtube-container .show-ranking-mobile {
    width: 100vw;
    max-height: 90px;
    color: #07dee7;
    padding: 1.25rem 0 0 0.625rem;
    font-size: 1.5rem; }

.side-bar {
  position: fixed;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 360px;
  max-width: 100vw;
  height: 100vh;
  background-color: #111724;
  transition: all 250ms ease-in-out;
  overflow: scroll; }
  @media only screen and (max-width: 500px) {
    .side-bar {
      width: 100vw; } }
  .side-bar.fullscreen {
    transform: translateX(360px); }
    @media only screen and (max-width: 500px) {
      .side-bar.fullscreen {
        transform: none; } }
  .side-bar .primary-button {
    background-color: transparent;
    border-radius: 4px;
    border: solid 1px #ff54ae;
    color: #ff54ae; }
  .side-bar .top-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #9f9faf;
    padding: 16px 20px 0;
    font-size: 0.875rem; }
    .side-bar .top-header i {
      color: #07dee7;
      font-size: 24px;
      margin-right: 10px; }
    @media only screen and (max-width: 500px) {
      .side-bar .top-header {
        padding: 26px 10px; }
        .side-bar .top-header i {
          margin-right: 0; } }
    .side-bar .top-header .logo {
      width: auto;
      height: 25px;
      object-fit: contain; }
    .side-bar .top-header .hide-ranking {
      cursor: pointer; }
    .side-bar .top-header .primary-button {
      align-self: flex-end; }
    .side-bar .top-header .add-circle {
      color: #ff54ae; }
    .side-bar .top-header .poop-mail {
      margin-right: 30px; }

.submit-container {
  color: #9f9faf; }
  .submit-container svg {
    fill: #9f9faf; }
  .submit-container hr {
    width: 100%;
    border: solid 1px #4c4b5e;
    margin: 30px 0; }
  .submit-container .ant-drawer-content {
    background-color: #111724; }
  .submit-container .submit-footer {
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 320px;
    border-top: solid 1px #4c4b5e;
    background-color: #111724;
    margin: 0 20px;
    padding: 10px 0;
    height: 72px;
    bottom: 0; }
    .submit-container .submit-footer .prev,
    .submit-container .submit-footer .next {
      cursor: pointer; }
    .submit-container .submit-footer .prev svg {
      margin-right: 4px; }
    .submit-container .submit-footer .next {
      color: #07dee7; }
      .submit-container .submit-footer .next svg {
        fill: #07dee7;
        margin-left: 4px; }
  .submit-container .ant-input-number,
  .submit-container .ant-input-number:focus,
  .submit-container .ant-input-number:hover,
  .submit-container .ant-input-number:active,
  .submit-container input,
  .submit-container input:focus,
  .submit-container input:hover,
  .submit-container input:active {
    background-color: transparent;
    border: none;
    color: #fff;
    box-shadow: none; }
  .submit-container .ant-slider:hover .ant-slider-track,
  .submit-container .ant-slider-track {
    background-color: #07dee7; }
  .submit-container .title {
    color: #07dee7;
    margin-bottom: 30px;
    align-self: center; }
  .submit-container .desc {
    margin-top: 15px;
    text-align: center;
    align-self: center; }
  .submit-container .dog-img {
    width: 110px;
    height: 110px;
    object-fit: contain;
    align-self: center; }
  .submit-container .step-submit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 56px 20px; }
    .submit-container .step-submit .author-title {
      margin-left: 10px; }
    .submit-container .step-submit .thumbnail {
      width: 100px;
      height: 80px;
      object-fit: contain; }
    .submit-container .step-submit .add-tags {
      margin-top: 20px;
      margin-bottom: 10px; }
    .submit-container .step-submit .tags {
      width: 100%;
      border-bottom: solid 1px #4c4b5e; }
    .submit-container .step-submit .tag {
      background: #4c4b5e;
      border: dashed 1px #9f9faf;
      color: #9f9faf;
      cursor: pointer;
      margin-bottom: 5px; }
    .submit-container .step-submit .input-desc {
      margin-top: 30px;
      margin-bottom: 10px; }
    .submit-container .step-submit .input-container {
      display: flex;
      width: 100%;
      height: 40px;
      align-items: center;
      padding: 0px 16px;
      border: 2px solid #07dee7;
      border-radius: 10px; }
      .submit-container .step-submit .input-container.tag-step {
        padding: 0;
        border: none;
        height: auto; }
        .submit-container .step-submit .input-container.tag-step .input {
          margin-left: 10px;
          text-decoration: underline;
          color: #07dee7; }
    .submit-container .step-submit button {
      width: 102px;
      height: 40px;
      border-radius: 7px;
      background-color: #ff54ae;
      border-color: #ff54ae;
      margin-top: 20px;
      color: #fff;
      margin-left: auto; }

.title-container {
  position: absolute;
  z-index: 5;
  width: 100%;
  top: 85px;
  left: 0;
  padding: 1.625rem 1.25rem; }
  @media only screen and (max-width: 500px) {
    .title-container {
      top: 50px;
      padding: 1.25rem 0.625rem; } }
  @media only screen and (max-height: 600px) {
    .title-container {
      top: 0;
      padding: 1.25rem 0.625rem; } }
  .title-container .info-container,
  .title-container .title-share {
    display: flex;
    width: 100%;
    max-width: 100vw; }
  .title-container .title-share {
    display: flex;
    align-items: center;
    font-size: 1.125rem; }
    .title-container .title-share .title-text {
      padding-right: 1.25rem;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      line-height: 1.5rem;
      /* fallback */
      max-height: 3rem;
      /* fallback */ }
  .title-container .flag-button {
    width: 24px;
    height: 24px;
    margin-right: 20px; }
  .title-container .youtube-button {
    width: 32px;
    height: 32px; }
  .title-container .share-button {
    width: 26px;
    height: 26px;
    margin-left: 20px; }
  .title-container .tags {
    position: absolute;
    top: 140px; }
  .title-container .badge-container {
    position: absolute;
    margin-top: 10px; }
  .title-container .badge {
    align-items: center;
    height: 60px;
    border-radius: 7px;
    padding: 0 0 0 15px;
    background-color: rgba(17, 23, 36, 0.8);
    white-space: nowrap; }
    .title-container .badge img {
      width: 24px;
      height: 32px;
      object-fit: contain;
      margin-right: 10px; }
  .title-container .divider {
    width: 1px;
    height: 37px;
    background-color: #4c4b5e;
    margin: 0 0 0 15px; }
  .title-container .upvote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ff54ae;
    font-size: 16px;
    padding: 0 20px 0 15px; }

.icon-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 14.5px;
  color: #07dee7;
  font-size: 12px;
  padding: 0 16px;
  background-color: rgba(17, 23, 36, 0.8); }
  .icon-tag img {
    margin-right: 3px; }

.ranking-list {
  padding-bottom: 100px; }
  .ranking-list .hunt-video-button {
    display: flex;
    align-items: center;
    height: 44px;
    width: 57px;
    padding-left: 8px;
    border-top-left-radius: 99px;
    border-bottom-left-radius: 99px;
    border-left-radius: 99px;
    background-color: #ff54ae; }
  .ranking-list .back-to-rank {
    margin: 0.625rem 1.25rem; }
    @media only screen and (max-width: 500px) {
      .ranking-list .back-to-rank {
        margin: 0 0.625rem; } }
  .ranking-list .section-container {
    padding: 0 0 0 1.25rem;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
    @media only screen and (max-width: 500px) {
      .ranking-list .section-container {
        padding: 0 0 0 0.625rem; } }
  .ranking-list .empty-list-message {
    margin-top: 1.25rem;
    padding: 0 1.25rem; }
    @media only screen and (max-width: 500px) {
      .ranking-list .empty-list-message {
        padding: 0 0.625rem; } }
    .ranking-list .empty-list-message .title {
      margin: 0; }
    .ranking-list .empty-list-message .description {
      margin-bottom: 1.875rem; }
  .ranking-list .title {
    font-size: 1.375rem; }
  .ranking-list .plus-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .ranking-list .plus-button-container .title {
      margin: 0 !important; }
  .ranking-list .ant-tabs-bar {
    margin-top: 0;
    padding-bottom: 0.5rem;
    border-bottom: 0.0625rem solid #4c4b5e;
    color: #9f9faf; }
    .ranking-list .ant-tabs-bar .ant-tabs-tab {
      font-size: 0.875rem;
      height: 1.5rem;
      margin-right: 1rem;
      margin-left: 1rem;
      padding: 0; }
    .ranking-list .ant-tabs-bar .ant-tabs-tab-active {
      color: #07dee7;
      border: none; }
    .ranking-list .ant-tabs-bar .ant-tabs-ink-bar {
      background-color: transparent; }
    .ranking-list .ant-tabs-bar svg {
      fill: #9f9faf;
      font-size: 1rem; }

.rank-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  transition: all 450ms ease-in-out; }
  @media only screen and (max-width: 500px) {
    .rank-item {
      padding: 0.625rem; } }
  .rank-item.selected {
    background-color: #1a2955; }
  .rank-item .rank {
    width: 2.625rem; }
    @media only screen and (max-width: 330px) {
      .rank-item .rank {
        width: 2rem; } }
    .rank-item .rank img {
      margin-left: -8px; }
  .rank-item .thumbnail-container {
    position: relative;
    min-width: 3.125rem !important;
    width: 3.125rem !important;
    height: 3.125rem !important;
    margin-right: 1rem; }
  .rank-item .rank-item-thumbnail {
    position: absolute;
    width: 100%;
    height: 140%;
    top: -10px;
    object-fit: cover;
    border-radius: 0.375rem; }
  .rank-item .upvote-button {
    height: 1.375rem; }
    .rank-item .upvote-button.liked {
      background-color: #ff54ae !important;
      color: #fff !important; }
  .rank-item .video-detail {
    display: flex;
    flex: 1;
    overflow: hidden; }
    @media only screen and (max-width: 330px) {
      .rank-item .video-detail {
        width: 12.5rem; } }
  .rank-item .text {
    margin-right: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-height: 1.5rem;
    /* fallback */
    max-height: 3rem;
    /* fallback */ }
  .rank-item .primary-button {
    min-width: 4.5rem;
    padding: 0rem 0.375rem; }
    .rank-item .primary-button i {
      margin-right: 0.25rem; }

.loading-circle {
  width: 100%;
  text-align: center;
  margin: 1.25rem 0; }

.login-modal svg {
  fill: #fff; }

.login-modal .logo {
  width: 127px;
  height: 40px;
  object-fit: contain; }

.login-modal .circle-image {
  width: 80px;
  height: 80px;
  object-fit: center; }

.login-modal .dash-container {
  margin-top: 40px; }

.login-modal .ant-modal-body {
  padding: 0; }

.login-modal .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; }

.login-modal .title {
  width: 331px;
  max-width: 100%;
  font-size: 1.75rem;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 20px; }

.login-modal .ant-modal {
  width: 720px !important;
  max-width: 100vw; }

.login-modal .ant-modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111724;
  padding: 50px 100px; }

.login-modal .dashed-dots {
  width: 90px;
  border: dashed 2px #07dee7; }

.login-modal .primary-button {
  width: auto;
  height: 40px;
  margin-top: 40px; }

.login-modal .what-is-blockstack {
  margin-top: 20px;
  text-decoration: underline;
  text-decoration-color: #9f9faf; }

.noise-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 99;
  opacity: 1;
  transition: opacity 0.5s linear;
  pointer-events: none; }
  .noise-container.fade-out {
    opacity: 0; }
  .noise-container .absolute-center {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 360px);
    height: 100%;
    top: 0;
    left: 0; }
    @media only screen and (max-width: 500px) {
      .noise-container .absolute-center {
        width: 100vw; } }
    .noise-container .absolute-center img {
      width: 30vw;
      min-width: 250px;
      height: auto;
      object-fit: contain; }
    .noise-container .absolute-center.fullscreen {
      width: 100vw; }

.home {
  width: 100vw;
  background-color: #000; }
  .home .show-ranking {
    position: absolute;
    display: flex;
    align-items: center;
    top: 22.5px;
    right: 1.875rem;
    opacity: 0;
    transition: opacity 0ms linear;
    cursor: pointer; }
    @media only screen and (max-width: 500px) {
      .home .show-ranking {
        right: 0.9375rem; } }
    @media only screen and (max-height: 600px) {
      .home .show-ranking {
        top: 1.25rem;
        right: 0.9375rem; } }
    .home .show-ranking i {
      color: #07dee7;
      font-size: 24px;
      margin-right: 10px; }
    .home .show-ranking .text {
      margin-top: 2px; }
      @media only screen and (max-width: 500px) {
        .home .show-ranking .text {
          display: none; } }
    .home .show-ranking.visible {
      opacity: 1;
      transition: opacity 1000ms linear;
      transition-delay: 250ms; }

.tooltip-menu .tooltip-menu-item {
  width: 128px;
  padding-left: 8px;
  cursor: pointer;
  margin: 4px 0; }

.show-more {
  margin-top: 1.25rem;
  text-align: center; }

.newsletter-page {
  display: flex;
  justify-content: center; }
  .newsletter-page .content {
    display: flex;
    flex-direction: column;
    width: 600px;
    max-width: 100vw;
    margin-top: 3.75rem;
    align-items: center; }
    .newsletter-page .content .poop-logo {
      width: 65px;
      height: 60px; }
    .newsletter-page .content .poop-elmo {
      width: 100%;
      height: auto;
      margin-top: 1.875rem; }
    .newsletter-page .content .title {
      font-family: Itim, sans-serif;
      color: #fff;
      font-size: 1.25rem;
      margin-top: 0.5rem; }
    .newsletter-page .content .text-container {
      background-color: #111724;
      color: #fff;
      padding: 2.5rem; }
    .newsletter-page .content input {
      background-color: transparent;
      color: #ff54ae;
      border-color: #ff54ae;
      border-radius: 0;
      height: 40px; }
    .newsletter-page .content .ant-input-group-addon {
      background-color: #ff54ae;
      border: transparent;
      border-radius: 0;
      color: #fff; }
    .newsletter-page .content .primary-button {
      font-size: 15px;
      height: 40px;
      margin-top: 20px; }

.unsubscribe-page {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 90px 10px; }
  .unsubscribe-page .poop-icon {
    width: 129px;
    height: auto;
    object-fit: contain; }
  .unsubscribe-page .content {
    display: flex;
    flex-direction: column;
    width: 520px;
    max-width: 100vw;
    align-items: center;
    text-align: center; }
    .unsubscribe-page .content .title {
      font-family: Itim;
      font-size: 2.5rem; }
    .unsubscribe-page .content .desc {
      line-height: 1.33;
      margin: 50px 0; }
    .unsubscribe-page .content .primary-button {
      width: 146px; }
    .unsubscribe-page .content .link {
      margin-top: 16px; }
